import React from 'react';
import './Body1.css';
import { Button } from '@mui/material';
import imagem from './images/fraktal-1-1024x1010.jpg.webp';
import imagem2 from './images/fraktal-2-1024x1024.jpg.webp';
import logoacutis from './images/f1.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TagManager from 'react-gtm-module';
import { trackEvent } from '../utils/facebook-pixel';


function Body1() {

    const handleButtonClick = () => {

        TagManager.dataLayer({
            dataLayer: {
                event: 'button_click_3',
                buttonName: 'Btn3',
            },
        });

        trackEvent("button_click_3")
        window.open('https://wa.me/5583981557961?text=Ol%C3%A1%2C%20gostaria%20de%20solicitar%20um%20or%C3%A7amento%20para%20o%20desenvolvimento%20de%20um%20sistema.%20Como%20posso%20proceder%3F', '_blank');
    };

    return (
        <div id="sobre" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 125, paddingBottom: 50 }}>
            <div className='div-text' style={{ display: 'flex' }}>
                <div className="texto-principal-body">
                    <h1>Software personalizado para seu negócio</h1>
                    <h3>Desenvolvemos sistemas únicos que atendem perfeitamente às necessidades da sua empresa. Nossa equipe trabalha em estreita colaboração com você para entender seus desafios específicos e criar soluções tecnológicas que realmente funcionam. </h3>
                    <h3>Através de uma análise detalhada do seu negócio, transformamos processos complexos em ferramentas intuitivas e eficientes. Cada linha de código é escrita pensando na realidade da sua empresa, garantindo que o software não apenas resolva problemas atuais, mas também acompanhe seu crescimento futuro.</h3>
                    <Button className="agende-uma-reuniao" onClick={handleButtonClick} style={{ textTransform: 'none' }} variant="contained">Agende uma Reunião</Button>
                </div>
                <div className='div-img' style={{ marginLeft: 100 }}>
                    <img src={logoacutis} width={500} />
                </div>
            </div>
            {/* <div className='div-text2' style={{ display: 'flex', marginTop: 100, marginRight: 150 }}>
                <div className='div-img2' style={{ marginLeft: 100 }}>
                    <img src={imagem2} width={550} />
                </div>
                <div className="texto-principal-body" style={{ cursor: 'default' }}>
                    <div className="box">
                        <h1>Transparência total</h1>
                        <h2>Acompanhe o avanço do seu projeto como nunca antes, em uma plataforma centralizada e intuitiva.</h2>
                    </div>
                    <div className="box">
                        <h1>Aprovação de Etapas</h1>
                        <h2> Revise e aprove diretamente no aplicativo, tornando o processo ágil e colaborativo.</h2>
                    </div>
                    <div className="box">
                        <h1>Gestão de múltiplos projetos</h1>
                        <h2>Se você tem mais de um projeto conosco, o Cozinha Aberta os centraliza em um único lugar para facilitar o acompanhamento.</h2>
                    </div>
                </div>
            </div> */}
            {/* <Button className="fale-com-um-especialista" href='https://wa.me/5583981557961' target="_blank" style={{ textTransform: 'none' }} variant="contained">
                <WhatsAppIcon
                    sx={{
                        marginRight: 1,
                        fontSize: 30
                    }}
                />
                Fale com um especialista
            </Button> */}
        </div>
    );
}

export default Body1;
