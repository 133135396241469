import { Button, CardActions, Typography } from '@mui/material';
import React from 'react';
import './Header.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import imagem from './images/6.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TagManager from 'react-gtm-module';
import { trackEvent } from '../utils/facebook-pixel';


function Header() {

    const handleButtonClick = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'button_click_2',
                buttonName: 'Btn2',
            },
        });

        trackEvent("button_click_2")
        window.open("https://wa.me/5583981557961", "_blank")
    };

    return (
        <header style={{ backgroundColor: "#001f3a" }}>
            <nav className="top">
                <div>
                    <img width={80} src={imagem} />
                </div>
                {/* <div className="navigation">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/#sobre">Sobre nós</a></li>
                        <li><a href="/#servicos">Serviços</a></li>
                        <li><a href="/#como-funciona">Como Funciona</a></li>
                        <li><a href="/#projetos">Projetos</a></li>
                        <li><a href="/#contato">Contato</a></li>
                    </ul>
                </div> */}
            </nav>
            <div className="texto-principal">
                <h1 style={{ textTransform: 'uppercase' }}>Desenvolvemos softwares únicos que impulsionam seu negócio</h1>
                <div className='button-div'>
                    <Button className="fale-conosco"
                        onClick={handleButtonClick}
                        style={{ textTransform: 'none' }} variant="contained">
                        <WhatsAppIcon
                            sx={{
                                marginRight: 1,
                                fontSize: 30
                            }}
                        />
                        Fale Conosco
                    </Button>
                </div>
            </div>
            <div className="caixas">
                <Card sx={{ width: 400 }} className="card1">
                    <CardContent>
                        <Typography variant="h5" component="div" className='porcentagem'>
                            Qualidade
                        </Typography>
                        <Typography variant="body2" className="card-descricao">
                            Excelência técnica com entregas rápidas. Desenvolvemos seu app com as melhores práticas e tecnologias do mercado.
                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={{ width: 400, borderBottomColor: '#019875' }} className="card1">
                    <CardContent>
                        <Typography variant="h5" component="div" className='porcentagem'>
                            Inovação
                        </Typography>
                        <Typography variant="body2" className="card-descricao">
                            Transforme seu investimento em valor real. Construímos soluções eficientes que impulsionam o crescimento do seu negócio.
                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={{ width: 400, marginRight: 0, borderBottomColor: '#019875' }} className="card1">
                    <CardContent>
                        <Typography variant="h5" component="div" className='porcentagem'>
                            Resultado
                        </Typography>
                        <Typography variant="body2" className="card-descricao">
                            Monetização acelerada do seu projeto. Comece a ter retorno do seu investimento mais rápido com entregas em tempo recorde.
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        </header >
    );
}

export default Header;
