import React from 'react';
import './Body.css';
import Body1 from './Body1';
import Body2 from './Body2';
import Body3 from './Body3';
import Body4 from './Body4';
import Body5 from './Body5';

function Body() {
    return (
        <div className='body'>
            <Body1 />
            <Body2 />
            <Body5 />
            {/* <Body3 /> */}
            {/* <Body4 /> */}
        </div>
    );
}

export default Body;
