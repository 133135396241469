import React from 'react';
import './Body4.css';
import image from './images/1187626-Vericode-Artigo09-Capa_Blog.webp'
import { Button } from '@mui/material';


function Body4() {
    return (
        <div>
            <div className="content">
                <div className='vamos-nessa-text' style={{ marginLeft: 200 }}>
                    <h1>Seu app ou <br></br>sistema <strong>em apenas 90 dias!</strong></h1>
                    <Button className="vamos-nessa" href='https://wa.me/5583981557961?text=Ol%C3%A1%2C%20gostaria%20de%20conversar%20sobre%20uma%20parceria%20para%20desenvolvimento%20de%20solu%C3%A7%C3%B5es%20digitais%3F' target="_blank" style={{ textTransform: 'none' }} variant="contained">Vamos Nessa?</Button>
                </div>
                <div className='img-div'>
                    <img src={image} />
                </div>
            </div>
        </div>
    );
}

export default Body4;
