import ReactPixel from 'react-facebook-pixel';

const PIXEL_ID = '576923424796897';

const options = {
    autoConfig: true,
    debug: false  // Mude para true durante desenvolvimento
};

export const initFacebookPixel = () => {
    ReactPixel.init(PIXEL_ID, options);
};

export const trackPageView = () => {
    ReactPixel.pageView();
};

export const trackEvent = (event, data = null) => {
    ReactPixel.track(event, data);
};