import React, { useState, useEffect } from 'react';
import './Slider.css';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

const Card = ({ images, title, description }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <div
            className="card"
            style={{
                width: 400,
                height: 800,
                padding: 4,
                borderRadius: 4,
                border: '1px solid #eeeeee',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                margin: "8px 0px"
            }}
        >
            <div
                className="image-container"
                style={{
                    position: 'relative',
                    width: '100%',
                    height: '90%',
                    overflow: 'hidden',
                    borderRadius: 4,
                }}
            >
                <img
                    src={images[currentImageIndex]}
                    alt={`Projeto ${title}`}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'top',
                    }}
                />
                {images.length > 1 && (
                    <>
                        <ArrowBackIosNewOutlinedIcon
                            onClick={prevImage}
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: 8,
                                transform: 'translateY(-50%)',
                                fontSize: 24,
                                color: '#fff',
                                cursor: 'pointer',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                borderRadius: '50%',
                                padding: 4,
                            }}
                        />
                        <ArrowForwardIosOutlinedIcon
                            onClick={nextImage}
                            style={{
                                position: 'absolute',
                                top: '50%',
                                right: 8,
                                transform: 'translateY(-50%)',
                                fontSize: 24,
                                color: '#fff',
                                cursor: 'pointer',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                borderRadius: '50%',
                                padding: 4,
                            }}
                        />
                    </>
                )}
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    width: '90%',
                    marginTop: 8,
                }}
            >
                <div style={{ fontSize: 16, color: '#000' }}>{title}</div>
                <div style={{ fontSize: 12, color: '#777' }}>{description}</div>
            </div>
        </div>
    );
};

const CardContainer = ({ projects }) => {
    return (
        <div className="card-container">
            {projects.map((project, index) => (
                <Card
                    key={index}
                    title={project.title}
                    description={project.description}
                    images={project.images}
                />
            ))}
        </div>
    );
};

export default CardContainer;
